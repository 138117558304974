const FeatureToggleDefinitions = {
    'pms-reconnect': {
        description: 'Allow Apaleo customers to reconnect their Sweeply account to their PMS for enabling the Traces feature'
    },
    'hostpms-dev-server': {
        description: 'Force to use Host Dev server instead of production one. This is only needed for our test account'
    },
    'disable-cleaning-status-update': { description: 'Do not updated cleaning status in PMS' },
    feature1: { description: 'test feature' },
    cli: { description: 'The code is invoked in the context of our cli' },
    feature2: { description: 'test feature' },
    feature3: { description: 'test feature' },
    'guest-flow': { description: 'Open Guest page QR code' },
    workloadReports: { description: 'Enable workload reports' },
    'roles-and-permissions': { description: 'Enable roles and permissions' },
    'oracle-sync-bookings-by-checkin-checkout': { description: 'Enable syncing bookings for Oracle by check-in-check-out filters' },
    'disable-welcome-new-users': { description: 'Disable sending welcome email to new users' },
    'disable-mobile-notifications': { description: 'Disable sending mobile notifications' },
    'housekeeping-projection': { description: 'Enable creating and maintaining projections' },
    'stayery-cleaning-schedule': {
        description: 'Enable Stayery cleaning schedule tweaks that allows them to set clean rooms to dirty in cleaning schedule'
    },
    'allow-update-unit-description': { description: 'Allow the update of the description field of a unit during the syncing of units' },
    checklists: { description: 'Enable checklists' },
    'display-projection': { description: 'Enable housekeeping and task views using  projections' },
    'blue-lagoon-dev-server': {
        description: 'Force to use Blue Lagoon Dev server instead of production one. This is only needed for our test account'
    },
    'use-pubsub-topics': { description: 'Enable updating cleaning on PMS status by PubSub' },
    'bookings-commit-disabled': { description: 'Disable committing bookings changes to the DB' },
    'clean-until-checkin': { description: 'Keep unit clean until guest checks in' },
    'disable-apaleo-ooo-blocks': { description: 'Disable pull Apaleo OOO/OOS blocks' },
    'stayery-custom-roles': { description: 'Custom role for Stayery orgs' },
    snap: { description: 'Enable Snap views' },
    'read-only-cleaning-schedule': { description: 'Make the cleaning schedule read only' },
    'new-ooo-behaviour': { description: 'Testing out new behaviour for ooo and oos' },
    'no-auto-due-date': { description: 'Dont set due date automatically to lost and found issues' },
    'allow-out-of-service-update-from-pms': {
        description: 'Allow updating out-of-service cleaning status on our side when it is changed on PMS'
    },
    'mass-assign-backend': { description: 'Enable mass assign using the backend API' },
    'smart-group-selection': { description: 'Enable smart group selection in team assignment mode' },
    'new-optional-calculations': { description: 'Enable the new opt-in/opt-out agnostic calculations' }
}

export type Features = keyof typeof FeatureToggleDefinitions
export const FeaturesList = Object.keys(FeatureToggleDefinitions) as Features[]

export function parseConfig(featureNames: string) {
    return featureNames.split(',').map(s => s.trim())
}

export function getFeaturesAtScope(entity: { featuresEnabled?: string; featuresDisabled?: string }) {
    const featuresEnabled = entity['featuresEnabled']
    const featuresDisabled = entity['featuresDisabled']
    return {
        featuresEnabled: typeof featuresEnabled === 'string' ? parseConfig(featuresEnabled) : [],
        featuresDisabled: typeof featuresDisabled === 'string' ? parseConfig(featuresDisabled) : []
    }
}

export function getClientFeatureScope(clientFeatures: { [name in Features]?: boolean }) {
    return Object.entries(clientFeatures).reduce(
        (acc, v) => ({
            featuresEnabled: acc.featuresEnabled.concat(v[1] ? [v[0]] : []),
            featuresDisabled: acc.featuresDisabled.concat(v[1] ? [] : [v[0]])
        }),
        { featuresEnabled: [] as string[], featuresDisabled: [] as string[] }
    )
}

export function featureAtScope(scope: { featuresEnabled: string[]; featuresDisabled: string[] }, featureName: Features) {
    if (scope.featuresDisabled.includes(featureName)) {
        return false
    }
    if (scope.featuresEnabled.includes(featureName)) {
        return true
    }
    return undefined
}

export function isFeatureOn(entity: { featuresEnabled?: string; featuresDisabled?: string }, featureName: Features) {
    return featureAtScope(getFeaturesAtScope(entity), featureName) ?? false
}
export function featuresEnabled(entity: { featuresEnabled?: string; featuresDisabled?: string }) {
    const featuresAtScope = getFeaturesAtScope(entity)
    return FeaturesList.filter(f => featureAtScope(featuresAtScope, f) ?? false)
}
